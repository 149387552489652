import { LuGlobe2, LuSparkle, LuHeart, LuHome } from "react-icons/lu";

export default function ProviderStory({ story }: { story: string }) {
  return (
    <div className="flex flex-col gap-6">
      {story
        .split("\n")
        .filter((line) => line.trim() !== "")
        .map((line, index) => {
          const header = line.split("-")[0].trim().slice(2);
          const text = line.split("-").slice(1).join("-").trim();
          return (
            <div key={line} className="flex flex-row items-start gap-4 pl-2">
              {index === 0 && <LuHome className="size-5 mt-2" />}
              {index === 1 && <LuHeart className="size-5 mt-2" />}
              {index === 2 && <LuGlobe2 className="size-5 mt-2" />}
              {index === 3 && <LuSparkle className="size-5 mt-2" />}
              <div className="flex-1">
                <p className="text-sm text-default-600">
                  <span className="font-semibold">{header}</span> - {text}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
}
