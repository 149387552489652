import { Card, CardBody, Avatar } from "@nextui-org/react";
import { LuTrendingUp } from "react-icons/lu";
import { getPublicAssetURL } from "~/utils/assets";

export default function ProviderCard({
  name,
  instagram_followers,
  image,
}: {
  name: string;
  instagram_followers: number | null;
  image: string | null;
}) {
  return (
    <Card className="mb-2">
      <CardBody className="p-6 items-center gap-4">
        <Avatar
          className="w-28 h-28 rounded-full object-cover"
          alt={name}
          isBordered
          src={image ? getPublicAssetURL(image) : "/assets/blur.webp"}
        />
        <div>
          <p className="font-semibold text-center">{name}</p>
          {instagram_followers && (
            <p className="text-sm text-default-500 text-center flex items-center justify-center gap-2">
              <LuTrendingUp className="size-4" />
              {Math.floor(instagram_followers / 87)} connects in last 3 months
            </p>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
